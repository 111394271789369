<template>
  <div class="batchAdd_main">
    <pageHead class="messageMainView_head" :title="title" />
    <van-row class="search_box">
      <van-col span="20">
        <circleSearchs :placeholder="placeholder" @click="handleClick" @input="input" :disabled="false"></circleSearchs>
      </van-col>
      <van-col span="2">
        <div class="cancal" @click.prevent="cancal"><span class="cancal_title">取消</span></div>
      </van-col>
    </van-row>

    <!-- 按企业架构添加 -->
    <p class="van_cell_box" v-if="vanSwipeCur.length > 0 && navShow">
      <van-cell title="按企业架构添加" class="qztx" is-link />
    </p>
    <!-- 列表 -->
    <div class="vant_List" v-if="vanSwipeCur.length > 0">
      <van-swipe-cell v-for="(item,index) in vanSwipeCur" :key="item.id"
        @click.native.prevent="vanSwipeClick(item,index)">
        <span :class="item.isCheck ? 'active_icon' : 'checkbox_icon'"></span>
        <van-card
          :title="item.title"
          class="goods-card"
          :thumb="item.header"
        />
      </van-swipe-cell>
    </div>
    <!-- 无数据展示 -->
    <template v-else>
      <div class="hotCircle_noData">
        <div class="noData_box">
          <div class="noData_img"></div>
          <span>{{ noDataInfo.title1 || '暂未数据' }}</span>
        </div>
      </div>
    </template>

    <footer>
      <div class="uploader_box">
        <div class="circleList" v-if="fileList.length > 0">
          <div class="circleLi">
            <div class="item_cir" v-for="(item) in fileList" :key="item.id">
              <div class="van_image_box">
                <div class="van_image_lsBox" @click="vanCancalClick(item)"><span class="van_image_ls"></span></div>
                <van-image
                  width="48px"
                  height="48px"
                  fit="cover"
                  position="left"
                  src="https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg"
                />
              </div>
              <div class="cir_title">{{ item.title }}</div>
            </div>
          </div>
        </div>

        <span class="submitAdd2" :style="{background: btnColor}"
          v-if="fileList.length>0" @click="fileListAdd">{{ conInfo }}({{ fileList.length }})</span>
        <span class="submitAdd" v-else>{{ conInfo }}</span>
      </div>
      <p class="button_line"></p>
    </footer>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import circleSearchs from '@/components/searchBox'

export default {
  name: 'batchAdd',
  components: {
    pageHead,
    circleSearchs
  },
  props: {
    title: { // 标题
      type: String,
      default: '标题'
    },
    placeholder: {
      type: String,
      default: ''
    },
    navShow: { // 是否显示按企业架构添加
      type: Boolean,
      default: false
    },
    vanSwipeList: { // 人员列表
      type: Array,
      default: () => []
    },
    noDataInfo: { // 没有数据
      type: Object,
      default: () => {
      }
    },
    conInfo: { // 按钮文案
      type: String,
      default: '确认添加'
    },
    btnColor: {
      type: String,
      default: ''
    }
  },
  created() {
    this.vanSwipeCur = this.vanSwipeList
  },
  data() {
    return {
      vanSwipeIn: [],
      number: '',
      fileList: [],
      vanSwipeCur: []
    }
  },
  methods: {
    // 取消
    cancal() {
      this.$router.back()
    },
    // 输入框
    input(v) {
      this.$emit('input', v)
    },
    // 输入框点击
    handleClick(v) {
      this.$emit('handleClick', v)
    },
    // 多选
    vanSwipeClick(item) {
      if (item.isCheck) {
        this.fileList.splice(item, 1)
      } else {
        this.fileList.push({id: item.id, url: item.header, isCheck: true})
      }
      this.$emit('vanSwipeClick', item)
    },
    // 添加
    fileListAdd() {
      this.$emit('fileListAdd')
    },
    vanCancalClick(item) {
      if (item.isCheck) {
        this.fileList.splice(item, 1)
      } else {
        this.fileList.push({id: item.id, url: item.header, isCheck: true})
      }
      this.$emit('vanSwipeClick', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.batchAdd_main {
  background: #fff;
  height: 100%;

  .van_cell_box {
    ::v-deep .van-cell {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f4f4f4;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
  }

  .circleList {
    overflow: scroll;
    width: 230px;
    float: left;

    .circleLi {
      display: flex;
    }

    .item_cir:first-child {
      margin-left: 0;
    }

    .item_cir {
      margin-right: 5px;

      .cir_title {
        font-size: 12px;
        color: #333333;
        text-indent: 6px;
        margin-top: 6px;
        letter-spacing: 0;
      }

      .van_image_box {
        position: relative;

        .van_image_lsBox {
          position: absolute;
          right: 0;
          z-index: 88;
          top: -10px;

          .van_image_ls {
            display: inline-block;
            width: 14px;
            height: 14px;
            color: #fff;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.75);
            position: relative;

            &::after {
              content: '-';
              position: absolute;
              left: 2px;
              top: -9px;
            }
          }
        }

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }
  }

  .uploader_box {
    padding: 18px 15px 0 15px;
    overflow: hidden;

    .submitAdd {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #f6f6f6;
      border-radius: 100px;

      font-size: 13px;
      text-align: CENTER;
      color: #6d7278;
      line-height: 35px;
      float: right;
    }

    .submitAdd2 {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #FE5800;
      border-radius: 100px;
      font-size: 13px;
      text-align: CENTER;
      color: #fff;
      line-height: 35px;
      float: right;
    }
  }

  .search_box {
    padding-top: 12px;

    ::v-deep .van-search__content {
      height: 41px;
      padding-left: 15px;
    }

    ::v-deep .van-cell {
      line-height: 31px;
    }
  }

  ::v-deep .van-search__content {
    border-radius: 70px;
  }

  .cancal {
    position: relative;
    left: 10px;
    top: 3px;

    .cancal_title {
      font-size: 14px;
      text-align: center;
      color: #000000;
      line-height: 14px;
    }
  }

  .vant_List {
    margin-top: 16px;
    height: 480px;
    overflow: scroll;

    ::v-deep {
      .van-swipe-cell__wrapper {
        display: flex;
        border-bottom: 1px solid #f4f4f4;
      }

      .van-card__title {
        padding-left: 0;
      }

      .van-card {
        padding-left: 10px;
        padding: 0 0 0 10px;
      }

      .van-card:not(:first-child) {
        margin-top: 0;
      }

      .van-swipe-cell:first-child {
        margin-top: 0;
      }

      .van-swipe-cell {
        margin-top: 16px;
        padding: 0 15px;
      }
    }

    .checkbox_icon {
      width: 21px;
      height: 21px;
      position: relative;
      top: 10px;
      box-sizing: border-box;
      display: inline-block;
      opacity: 0.9;
      border-radius: 50%;
      border: 2px solid #dcdcdc;
    }

    .active_icon {
      width: 21px;
      height: 21px;
      position: relative;
      top: 10px;
      box-sizing: border-box;
      display: inline-block;
      background: url('~@/assets/imgs/circle/Slice43@2x.png');
      background-size: 100%;
    }
  }

  .hotCircle_noData {
    display: flex;
    height: 450px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .noData_box {
      margin-bottom: 16px;

      .noData_img {
        width: 212px;
        height: 114px;
        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    span {
      font-size: 12px;
      color: #6d7278;
    }
  }

  footer {
    width: 100%;
    height: 108px;
    position: absolute;
    background: #fff;
    border-top: 1px solid #f4f4f4;
    bottom: 0;

    .button_line {
      width: 134px;
      height: 5px;
      opacity: 0.6;
      background: #333333;
      border-radius: 3px;
      margin: 0 auto;
      margin-top: 30px;
      bottom: 9px;
    }
  }
}
</style>
